@import "../constant";

// sumo 选择框的样式
.SumoSelect {
  & > .CaptionCont {
    border: 1px solid #ccc;
    & > span.placeholder {
      font-style: normal;
    }
  }
  & > .optWrapper {
    & > .options {
      li label {
        font-weight: 100;
        color: #333333;
      }
    }
  }
}
